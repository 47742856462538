import {
  ArrowForward,
  CalendarTodayOutlined,
  Check,
  Checklist,
  StoreOutlined,
} from '@mui/icons-material';
import { Chip, IconButton, Stack, type SvgIconProps } from '@mui/material';
import UserWithInitialsChip from 'components/common/UserWithInitialsChip';
import Grid, { type GridProps } from 'components/common/grids/Grid';
import {
  type DashboardDueTodayResponseDto,
  DashboardDueTodayType,
  DashboardParticipantType,
} from 'dtos/dashboard.dtos';
import type { TypedGridColDef } from 'types/typedGridColDef';
import { UserWithInitials } from './utils';

function IconButtonWithCheck({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled: boolean;
}) {
  return (
    <IconButton
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onClick();
      }}
      disabled={disabled}
    >
      <Check color={disabled ? 'success' : 'action'} fontSize="small" />
    </IconButton>
  );
}

const getIcons = ({
  type,
  onCheckmarkClick,
  taskIsCompleted,
}: {
  type: DashboardDueTodayType;
  onCheckmarkClick: () => void;
  taskIsCompleted: boolean;
}) => {
  const iconProps: SvgIconProps = {
    color: 'action',
    fontSize: 'small',
  };

  switch (type) {
    case DashboardDueTodayType.BOOKING_PAYMENT:
      return (
        <Stack direction="row" gap={2} ml={1}>
          <ArrowForward {...iconProps} />
          <StoreOutlined {...iconProps} />
        </Stack>
      );
    case DashboardDueTodayType.CLIENT_PAYMENT:
      return (
        <Stack direction="row" gap={2} ml={1}>
          <StoreOutlined {...iconProps} />
          <ArrowForward {...iconProps} />
        </Stack>
      );
    case DashboardDueTodayType.TASK:
      return (
        <Stack direction="row" gap={1} alignItems="center">
          <IconButtonWithCheck
            onClick={onCheckmarkClick}
            disabled={taskIsCompleted}
          />
          <Checklist {...iconProps} />
        </Stack>
      );
    case DashboardDueTodayType.BIRTHDAY:
    case DashboardDueTodayType.ANNIVERSARY:
    case DashboardDueTodayType.PASSPORT_EXPIRATION:
      return (
        <Stack direction="row" gap={1} alignItems="center">
          <IconButtonWithCheck
            onClick={onCheckmarkClick}
            disabled={taskIsCompleted}
          />
          <CalendarTodayOutlined {...iconProps} />
        </Stack>
      );
  }
};

const getColumns = ({
  onCompleteTask,
  showingCompletedTasks,
}: {
  onCompleteTask: (taskId: string) => void;
  showingCompletedTasks: boolean;
}): TypedGridColDef<DashboardDueTodayResponseDto>[] => [
  {
    field: 'title',
    flex: 1,
    headerName: 'Title/Subtitle',
    renderCell: ({ row, value }) => (
      <Stack direction="row" gap={1} alignItems="center">
        {getIcons({
          type: row.type,
          onCheckmarkClick: () => onCompleteTask(row.id),
          taskIsCompleted: showingCompletedTasks,
        })}

        {value}
      </Stack>
    ),
  },
  {
    field: 'client',
    headerName: 'Client',
    flex: 1,
    renderCell: ({ value }) => {
      if (!value) {
        return '—';
      }

      return value.type === DashboardParticipantType.CLIENT ? (
        <UserWithInitialsChip
          userName={value.name}
          avatarColor={value.color}
          size="medium"
          redirectUrl={`/clients/${value.id}`}
        />
      ) : (
        value.name
      );
    },
  },
  {
    field: 'trip',
    headerName: 'Trip',
    flex: 1,
    renderCell: ({ value }) =>
      value?.name ? (
        <Chip
          variant="outlined"
          label={value?.name}
          size="small"
          clickable
          component="a"
          href={`/trips/${value.id}`}
        />
      ) : (
        '—'
      ),
  },
  {
    field: 'assignee',
    headerName: 'Assignee',
    flex: 1,
    renderCell: ({ value }) =>
      value ? (
        <UserWithInitials
          userName={value.name}
          avatarColor={value.color}
          profileImageUrl={value.profileImageUrl}
        />
      ) : null,
  },
];

export const DashboardDueTodayTable = (
  props: Omit<
    GridProps<DashboardDueTodayResponseDto>,
    'columns' | 'initialState'
  > & {
    showingCompletedTasks: boolean;
    onCompleteTask: (taskId: string) => void;
  },
) => {
  return (
    <Grid
      {...props}
      columns={getColumns({
        onCompleteTask: props.onCompleteTask,
        showingCompletedTasks: props.showingCompletedTasks,
      })}
      getRowId={(row) => `${row.id}_${row.assignee?.id}`}
      sx={{ height: '509px' }}
    />
  );
};
