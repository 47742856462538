import Dashboard from 'components/dashboard/Dashboard';
import PageTitle from 'components/layout/PageTitle';

export default function Home() {
  return (
    <>
      <PageTitle title="Dashboard" />
      <Dashboard />
    </>
  );
}
