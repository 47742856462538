import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import BookingDialog from 'components/bookings/BookingDialog/BookingDialog';
import ClientInvoiceDialog from 'components/clientInvoices/ClientInvoiceDialog';
import { useUserInfoContext } from 'context/user-info.context';
import {
  DashboardPaymentType,
  type DashboardPaymentsResponseDto,
} from 'dtos/dashboard.dtos';
import { useDashboardPayments, usePagination } from 'hooks';
import { queryTypes as qt, useQueryStates } from 'next-usequerystate';
import { DateRangeFilter, PaymentTypeFilter } from 'services/dashboard/types';
import { DashboardPaymentsTable } from './DashboardPaymentsTable';

const paymentTypeToLabel = {
  [PaymentTypeFilter.ALL]: 'All',
  [PaymentTypeFilter.BOOKING]: 'Booking Payments',
  [PaymentTypeFilter.CLIENT]: 'Client Payments',
};

const dateRangeToLabel = {
  [DateRangeFilter.ALL]: 'All',
  [DateRangeFilter.TODAY]: 'Due Today',
  [DateRangeFilter.NEXT_7_DAYS]: 'Next 7 Days',
  [DateRangeFilter.OVERDUE]: 'Overdue',
  [DateRangeFilter.UPCOMING]: 'Upcoming',
};

export default function DashboardPayments() {
  const userInfo = useUserInfoContext();

  const [qp, setQp] = useQueryStates({
    paymentType: qt
      .stringEnum(Object.keys(PaymentTypeFilter))
      .withDefault(PaymentTypeFilter.ALL),
    dateRange: qt
      .stringEnum(Object.keys(DateRangeFilter))
      .withDefault(DateRangeFilter.NEXT_7_DAYS),
    tripId: qt.string,
    bookingId: qt.string,
    clientInvoiceId: qt.string,
  });

  const pagination = usePagination<DashboardPaymentsResponseDto>(
    'dueDate',
    'asc',
    'payments',
  );
  const { data, meta, isLoading, error, refresh } = useDashboardPayments({
    page: pagination.page,
    pageSize: pagination.pageSize,
    sortModel: pagination.sortModel,
    typeFilter: qp.paymentType as PaymentTypeFilter,
    dateRange: qp.dateRange as DateRangeFilter,
  });

  const handleRowClick = (row: DashboardPaymentsResponseDto) => {
    setQp({
      tripId: row.tripId,
      ...(row.type === DashboardPaymentType.CLIENT
        ? { clientInvoiceId: row.id }
        : {}),
      ...(row.type === DashboardPaymentType.BOOKING && row.bookingId
        ? { bookingId: row.bookingId }
        : {}),
    });
  };

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        paddingBottom={1}
        alignItems="center"
      >
        <Typography variant="h5">Payments</Typography>
        <Box display="flex" flexDirection="row" gap={2}>
          <ToggleButtonGroup
            value={qp.paymentType}
            onChange={(_event, value) =>
              setQp({ paymentType: value as PaymentTypeFilter })
            }
            size="small"
            exclusive
          >
            {Object.values(PaymentTypeFilter).map((type) => (
              <ToggleButton
                key={type}
                value={type}
                selected={qp.paymentType === type}
              >
                {paymentTypeToLabel[type]}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          <ToggleButtonGroup
            value={qp.dateRange}
            onChange={(_event, value) =>
              setQp({ dateRange: value as DateRangeFilter })
            }
            size="small"
            exclusive
          >
            {Object.keys(dateRangeToLabel).map((status) => (
              <ToggleButton
                key={status}
                value={status}
                selected={qp.dateRange === status}
              >
                {dateRangeToLabel[status as keyof typeof dateRangeToLabel]}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>
      </Box>
      <DashboardPaymentsTable
        {...pagination}
        data={data}
        homeCurrency={userInfo?.homeCurrency}
        onRowClick={(row) => handleRowClick(row)}
        isLoading={isLoading}
        error={error}
        meta={meta}
      />
      {!!qp.bookingId && !!qp.tripId && (
        <BookingDialog
          tripId={qp.tripId}
          mode="edit"
          bookingId={qp.bookingId ?? undefined}
          open={!!qp.bookingId && !!qp.tripId}
          setOpen={(isOpen) => {
            if (!isOpen) {
              setQp({ bookingId: null, tripId: null });
            }
          }}
          onSuccess={() => {
            setQp({ bookingId: null, tripId: null });
            refresh();
          }}
        />
      )}
      {!!qp.clientInvoiceId && !!qp.tripId && (
        <ClientInvoiceDialog
          tripId={qp.tripId}
          clientInvoiceId={qp.clientInvoiceId ?? undefined}
          mode="edit"
          onSuccess={() => {
            setQp({ clientInvoiceId: null, tripId: null });
            refresh();
          }}
          open={!!qp.clientInvoiceId && !!qp.tripId}
          setOpen={(isOpen) => {
            if (!isOpen) {
              setQp({ clientInvoiceId: null, tripId: null });
            }
          }}
        />
      )}
    </div>
  );
}
