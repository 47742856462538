import { Chip, type ChipProps, ListItemButton } from '@mui/material';
import { styled } from '@mui/system';
import ChipDropdownSelector from 'components/common/ChipDropdownSelector';
import {
  type LeadStageResponseDto,
  SyntheticLeadStage,
  terminalStages,
} from 'dtos';
import useLeadStages from 'hooks/useLeadStages';
import {
  ChipColor,
  colorForStageType,
  getChipData,
} from 'utils/client/leadStageChip';

export interface LeadStageSelectorProps {
  selectedStage: LeadStageResponseDto;
  onSelect: (stage: LeadStageResponseDto) => Promise<void>;
  useTripVerbiage?: boolean;
  size?: ChipProps['size'];
}
const Dot = styled('div')(({ theme, color = 'red' }) => ({
  width: '10px',
  height: '10px',
  borderRadius: '50%',
  backgroundColor:
    theme.palette[color]?.main ||
    theme.palette.accent[color]?.main ||
    '#ebebeb',
  marginRight: theme.spacing(2),
}));

export default function LeadStageSelectorChip({
  selectedStage,
  onSelect,
  useTripVerbiage = false,
  size = 'medium',
}: LeadStageSelectorProps) {
  const { data: stages, isLoading } = useLeadStages();

  const availableStages =
    selectedStage.type in SyntheticLeadStage &&
    selectedStage.type !== SyntheticLeadStage.TRIP_CREATED
      ? filterNonAllowedStages(stages || [])
      : stages;

  const { label, icon } = getChipData({
    leadStage: selectedStage,
    useTripVerbiage,
  });

  return isLoading ? (
    <Chip label="Loading..." size={size} />
  ) : (
    <ChipDropdownSelector<LeadStageResponseDto>
      showDropdownIcon
      selected={selectedStage}
      listItems={availableStages || []}
      listItemRenderer={renderListItem}
      valueSelector={(stage) => stage.name}
      onSelect={onSelect}
      chipProps={{
        size,
        color: colorForStageType(selectedStage.type),
        label,
        icon,
        sx: {
          color:
            colorForStageType(selectedStage.type) === ChipColor.DEFAULT
              ? 'text.primary'
              : 'text.contrast',
        },
      }}
    />
  );
}

const renderListItem = (
  listItem: LeadStageResponseDto,
  i: number,
  stages: LeadStageResponseDto[],
): React.ReactElement => (
  <ListItemButton divider={shouldShowDivider(listItem, i, stages)}>
    <Dot color={colorForStageType(listItem.type)} />
    {listItem.name}
  </ListItemButton>
);

const shouldShowDivider = (
  listItem: LeadStageResponseDto,
  i: number,
  stages: LeadStageResponseDto[],
): boolean => {
  if (i === stages.length - 1) return false;
  if (listItem.type !== stages[i + 1].type) return true;
  return false;
};

const filterNonAllowedStages = (stages: LeadStageResponseDto[]) =>
  stages.filter((stage) => terminalStages.includes(stage.type));
