import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Stack, type SvgIconProps, Tooltip } from '@mui/material';
import { Avatar, alpha } from '@mui/material';
import T from 'components/common/T';
import { enqueueSnackbar as toast } from 'notistack';
import { updateDashboardTask } from 'requests/tasks';

export const handleCompleteTask = async (taskId: string) => {
  try {
    await updateDashboardTask({ taskId, isCompleted: true });
    toast('Task completed successfully');
  } catch (error) {
    toast(`Error completing task: ${error}`, { variant: 'error' });
  }
};

export function DashboardOverviewTripsTooltip({
  color,
}: {
  color?: SvgIconProps['color'];
}) {
  return (
    <Tooltip
      placement="right"
      title="Includes trips created in the last six months and any trips with departure dates in the future"
      slotProps={{
        tooltip: {
          sx: {
            maxWidth: 'none',
          },
        },
      }}
    >
      <HelpOutlineIcon {...(color ? { color } : {})} />
    </Tooltip>
  );
}

type UserWithInitialProps = {
  userName: string;
  avatarColor?: string;
  profileImageUrl?: string;
};

export const UserWithInitials = ({
  userName,
  avatarColor = '#fff',
  profileImageUrl,
}: UserWithInitialProps) => {
  const StyledAvatar = () => (
    <Avatar
      variant="circular"
      sx={{
        width: 24,
        height: 24,
        bgcolor: alpha(avatarColor, 0.2),
        ...(!profileImageUrl && {
          paddingTop: 0.3,
        }),
      }}
      src={profileImageUrl}
    >
      <T variant="caption" sx={{ color: avatarColor }}>
        {userName[0]?.toUpperCase() ?? '--'}
      </T>
    </Avatar>
  );

  return (
    <Stack direction="row" gap={0.75} alignItems="center">
      <StyledAvatar />
      {userName}
    </Stack>
  );
};
