import {
  CalendarTodayOutlined,
  Flight,
  MonetizationOnOutlined,
  SpaceDashboard,
  TaskAlt,
} from '@mui/icons-material';
import { Box, Tab, Tabs, useTheme } from '@mui/material';
import { DashboardTabs as DashboardTabsEnum } from './types';
import { DashboardOverviewTripsTooltip } from './utils';

type TabLabelProps = {
  icon: React.ReactNode;
  label: string;
  suffix?: React.ReactNode;
};

const TabLabel = ({ icon, label, suffix }: TabLabelProps) => {
  return (
    <Box display="flex" alignItems="center" gap={0.75}>
      {icon}
      {label}
      {suffix}
    </Box>
  );
};

type DashboardTabsProps = {
  tab: DashboardTabsEnum;
  onTabChange: (tab: DashboardTabsEnum) => void;
};

export default function DashboardTabs({
  tab,
  onTabChange,
}: DashboardTabsProps) {
  const theme = useTheme();

  return (
    <Tabs
      value={tab}
      onChange={(_, newValue) => onTabChange(newValue)}
      sx={{
        borderBottom: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Tab
        label={<TabLabel icon={<SpaceDashboard />} label="Overview" />}
        value={DashboardTabsEnum.OVERVIEW}
      />
      <Tab
        label={<TabLabel icon={<TaskAlt />} label="Tasks" />}
        value={DashboardTabsEnum.TASKS}
      />
      <Tab
        label={<TabLabel icon={<MonetizationOnOutlined />} label="Payments" />}
        value={DashboardTabsEnum.PAYMENTS}
      />
      <Tab
        label={<TabLabel icon={<CalendarTodayOutlined />} label="Dates" />}
        value={DashboardTabsEnum.DATES}
      />
      <Tab
        label={
          <TabLabel
            icon={<Flight />}
            label="Trips"
            suffix={<DashboardOverviewTripsTooltip />}
          />
        }
        value={DashboardTabsEnum.TRIPS}
      />
    </Tabs>
  );
}
