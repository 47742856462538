import { Add } from '@mui/icons-material';
import { Button, type ButtonProps, Stack, useTheme } from '@mui/material';
import BookingDialog from 'components/bookings/BookingDialog/BookingDialog';
import AddClientDialog from 'components/clients/individual/AddClientDialog';
import TripDialog from 'components/trips/TripDialog/TripDialog';
import TripTaskDialog from 'components/trips/TripTaskDialog/TripTaskDialog';
import { TripTab } from 'components/trips/types';
import { useRouter } from 'next/router';
import { useState } from 'react';

type StyledButtonProps = ButtonProps & {
  label: string;
};

const StyledButton = ({ label, ...props }: StyledButtonProps) => {
  return (
    <Button
      {...props}
      variant="contained"
      color="inherit"
      size="small"
      fullWidth
      disableElevation
    >
      <Add /> {label}
    </Button>
  );
};

enum QuickAddDialogParams {
  CLIENT = 'client',
  TRIP = 'trip',
  BOOKING = 'booking',
  TRIP_AND_BOOKING = 'trip-and-booking',
  TASK = 'task',
}

export default function DashboardQuickAddButtons() {
  const theme = useTheme();
  const router = useRouter();

  const [quickAddType, setQuickAddType] =
    useState<QuickAddDialogParams | null>();

  const handleClick = (mode: QuickAddDialogParams) => {
    setQuickAddType(mode);
  };

  const handleClose = () => {
    setQuickAddType(null);
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      mt={2}
      pb={2}
      spacing={1}
      borderBottom={`1px solid ${theme.palette.divider}`}
    >
      <StyledButton
        label="New Client"
        onClick={() => handleClick(QuickAddDialogParams.CLIENT)}
      />
      <StyledButton
        label="New Trip"
        onClick={() => handleClick(QuickAddDialogParams.TRIP)}
      />
      <StyledButton
        label="New Booking"
        onClick={() => handleClick(QuickAddDialogParams.BOOKING)}
      />
      <StyledButton
        label="Trip And Booking"
        onClick={() => handleClick(QuickAddDialogParams.TRIP_AND_BOOKING)}
      />
      <StyledButton
        label="New Task"
        onClick={() => handleClick(QuickAddDialogParams.TASK)}
      />
      {quickAddType === QuickAddDialogParams.CLIENT && (
        <AddClientDialog
          open
          onClose={handleClose}
          onSuccess={(client) => {
            router.push(`/clients/${client.id}`);
          }}
        />
      )}
      {quickAddType === QuickAddDialogParams.TRIP && (
        <TripDialog
          mode="add"
          open
          setOpen={handleClose}
          onSuccess={(trip) => {
            router.push(`/trips/${trip.id}`);
          }}
        />
      )}
      {quickAddType === QuickAddDialogParams.BOOKING && (
        <BookingDialog
          mode="add"
          open
          showTripSelector
          setOpen={handleClose}
          onSuccess={(_, booking) => {
            router.push(`/trips/${booking.trip.id}?tab=${TripTab.bookings}`);
          }}
        />
      )}
      {quickAddType === QuickAddDialogParams.TRIP_AND_BOOKING && (
        <BookingDialog
          mode="add"
          open
          isQuickAdd
          setOpen={handleClose}
          onSuccess={(_, booking) => {
            router.push(`/trips/${booking.trip.id}?tab=${TripTab.bookings}`);
          }}
        />
      )}
      {quickAddType === QuickAddDialogParams.TASK && (
        <TripTaskDialog
          mode="add"
          open
          showTripSelector
          setOpen={handleClose}
          onSuccess={(task) => {
            router.push(`/trips/${task.trip?.id}?tab=${TripTab.tasksList}`);
          }}
        />
      )}
    </Stack>
  );
}
