import type { ListItem } from 'components/types';
import type { TaskResponseDto, TaskUpdateRequestDto } from 'dtos';
import {
  TaskTriggerTiming,
  TaskTriggerTimingQualifier,
  TaskTriggerType,
} from 'dtos/workflowTemplates.types';
import type { FormikValues } from 'formik';
import { type ExtendedFormSchema, createYupSchema } from 'utils/client/types';
import * as yup from 'yup';

export enum FieldNames {
  TRIP = 'trip',
  TITLE = 'title',
  DESCRIPTION = 'description',
  ASSIGNEES = 'assignees',
  REMIND_AT = 'remindAt',
  IS_COMPLETED = 'isCompleted',
  DUE_AT = 'dueAt',
  TRIGGER = 'trigger',
  TRIGGER_TIMING = 'triggerTiming',
  TRIGGER_TIMING_QUALIFIER = 'triggerTimingQualifier',
  TRIGGER_TIMING_DURATION = 'triggerTimingDuration',
}

export interface TripTaskFormValues extends FormikValues {
  [FieldNames.TRIP]: ListItem | null;
  [FieldNames.TITLE]: string;
  [FieldNames.DESCRIPTION]: string;
  [FieldNames.ASSIGNEES]: (ListItem & { agencyUserId: string })[];
  [FieldNames.REMIND_AT]: Date | null;
  [FieldNames.IS_COMPLETED]: boolean;
  [FieldNames.DUE_AT]: Date | null;
  [FieldNames.TRIGGER]: TaskTriggerType;
  [FieldNames.TRIGGER_TIMING]: TaskTriggerTiming;
  [FieldNames.TRIGGER_TIMING_QUALIFIER]: TaskTriggerTimingQualifier;
  [FieldNames.TRIGGER_TIMING_DURATION]: number;
}

const nullableListItem = yup.object().nullable().shape({
  id: yup.string(),
  name: yup.string(),
});

const schema = ({
  showTripSelector,
}: {
  showTripSelector?: boolean;
}) => {
  const yupSchema = {
    trip: nullableListItem
      .when([], {
        is: () => showTripSelector,
        then: (_) =>
          nullableListItem.required('Trip is required').label('Trip'),
      })
      .label('Trip'),
    title: yup.string().required('Title is required'),
    description: yup.string().nullable(),
    assignees: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.string(),
          name: yup.string(),
        }),
      )
      .min(1, 'At least one assignee is required')
      .label('Assignee(s)'),

    remindAt: yup.date().nullable(),
    isCompleted: yup.boolean(),
    dueAt: yup
      .date()
      .nullable()
      .when('trigger', {
        is: TaskTriggerType.SPECIFIC_DATE,
        then: (schema) =>
          schema.required('Date is required').typeError('Date is required'),
        otherwise: (schema) => schema.nullable(),
      }),
    trigger: yup.string().oneOf(Object.values(TaskTriggerType)),
    triggerTiming: yup.string().oneOf(Object.values(TaskTriggerTiming)),
    triggerTimingQualifier: yup
      .string()
      .oneOf(Object.values(TaskTriggerTimingQualifier)),
    triggerTimingDuration: yup.number().min(0),
  };

  return createYupSchema<TripTaskFormValues>(yupSchema);
};

const getDefaultValues = (
  task: TaskResponseDto | undefined,
): TripTaskFormValues => {
  return {
    trip: task?.trip
      ? {
          id: task.trip.id,
          name: task.trip.name,
        }
      : null,
    title: task?.title || '',
    description: task?.description || '',
    assignees: task?.agencyUsers
      ? task.agencyUsers.map((user) => ({
          id: user.id,
          name: `${user.firstName} ${user.lastName}`,
          agencyUserId: user.id,
        }))
      : [],
    remindAt: task?.remindAt
      ? new Date(task?.remindAt)
      : task?.triggerDate
        ? new Date(task?.triggerDate)
        : null,
    isCompleted: task?.isCompleted || false,
    dueAt: task?.dueAt
      ? new Date(task?.dueAt)
      : task?.triggerDate
        ? new Date(task?.triggerDate)
        : null,
    trigger: task?.trigger ?? TaskTriggerType.NONE,
    triggerTiming: task?.triggerTiming ?? TaskTriggerTiming.DAY_OF,
    triggerTimingQualifier:
      task?.triggerTimingQualifier ?? TaskTriggerTimingQualifier.BEFORE,
    triggerTimingDuration: task?.triggerTimingDuration ?? 0,
  };
};

const getEntityFromFormValues = (
  values: TripTaskFormValues,
): TaskUpdateRequestDto & { tripId?: string } => ({
  tripId: values[FieldNames.TRIP]?.id ?? undefined,
  title: values[FieldNames.TITLE],
  description: values[FieldNames.DESCRIPTION],
  agencyUserIds: values[FieldNames.ASSIGNEES].map(
    (assignee) => assignee.agencyUserId,
  ),
  remindAt: values[FieldNames.REMIND_AT]?.toISOString() as string,
  dueAt: values[FieldNames.DUE_AT]?.toISOString() as string,
  isCompleted: values[FieldNames.IS_COMPLETED] || false,
  trigger: values[FieldNames.TRIGGER] ?? TaskTriggerType.NONE,
  triggerTiming: values[FieldNames.TRIGGER_TIMING] ?? TaskTriggerTiming.DAY_OF,
  triggerTimingQualifier:
    values[FieldNames.TRIGGER_TIMING_QUALIFIER] ??
    TaskTriggerTimingQualifier.BEFORE,
  triggerTimingDuration: values[FieldNames.TRIGGER_TIMING_DURATION] ?? 0,
});

export type SchemaContext = {
  showTripSelector?: boolean;
};

const formSchema: ExtendedFormSchema<
  TaskResponseDto,
  TaskUpdateRequestDto & { tripId?: string },
  TripTaskFormValues & { tripId?: string },
  typeof schema,
  SchemaContext
> = {
  schema,
  getDefaultValues,
  getEntityFromFormValues,
};

export default formSchema;
