import { Check } from '@mui/icons-material';
import { Chip, IconButton, Stack } from '@mui/material';
import UserWithInitialsChip from 'components/common/UserWithInitialsChip';
import Grid, { type GridProps } from 'components/common/grids/Grid';
import type { DashboardTasksResponseDto } from 'dtos/dashboard.dtos';
import type { TypedGridColDef } from 'types/typedGridColDef';
import { niceShortDate } from 'utils/client/formatting';
import { UserWithInitials } from './utils';

const getColumns = ({
  onCompleteTask,
  showingCompletedTasks,
}: {
  onCompleteTask: (taskId: string) => void;
  showingCompletedTasks: boolean;
}): TypedGridColDef<DashboardTasksResponseDto>[] => [
  {
    field: 'title',
    flex: 1,
    headerName: 'Title',
    renderCell: ({ row, value }) => (
      <Stack direction="row" gap={1} alignItems="center">
        <IconButton
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            onCompleteTask(row.id);
          }}
          disabled={showingCompletedTasks}
        >
          <Check
            color={showingCompletedTasks ? 'success' : 'action'}
            fontSize="small"
          />
        </IconButton>
        {value}
      </Stack>
    ),
  },
  {
    field: 'dueAt',
    headerName: 'Due',
    flex: 1,
    renderCell: ({ value }) => niceShortDate(value),
  },
  {
    field: 'client',
    headerName: 'Client',
    flex: 1,
    renderCell: ({ value }) =>
      value ? (
        <UserWithInitialsChip
          userName={value.name}
          avatarColor={value.color}
          size="medium"
          redirectUrl={`/clients/${value.id}`}
        />
      ) : null,
  },
  {
    field: 'trip',
    headerName: 'Trip',
    flex: 1,
    renderCell: ({ value }) =>
      value?.name ? (
        <Chip
          variant="outlined"
          label={value?.name}
          size="small"
          clickable
          component="a"
          href={`/trips/${value.id}`}
        />
      ) : null,
  },
  {
    field: 'advisor',
    headerName: 'Assignee',
    flex: 1,
    renderCell: ({ value }) =>
      value ? (
        <UserWithInitials
          userName={value.name}
          avatarColor={value.color}
          profileImageUrl={value.profileImageUrl}
        />
      ) : null,
  },
];

export const DashboardTasksTable = (
  props: Omit<
    GridProps<DashboardTasksResponseDto>,
    'columns' | 'initialState'
  > & {
    showingCompletedTasks: boolean;
    onCompleteTask: (taskId: string) => void;
  },
) => {
  return (
    <Grid
      {...props}
      columns={getColumns({
        onCompleteTask: props.onCompleteTask,
        showingCompletedTasks: props.showingCompletedTasks,
      })}
      getRowId={(row) => `${row.id}_${row.advisor?.id}`}
      sx={{ height: 'calc(100vh - 200px)' }}
    />
  );
};
