import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import type {
  DashboardDatesResponseDto,
  DashboardDatesSortKeys,
} from 'dtos/dashboard.dtos';
import {
  DATE_TYPE_LABELS,
  DATE_TYPE_VALUES,
  DashboardDateRange,
  type DateTypeValue,
} from 'dtos/dashboard.dtos';
import { useDashboardDates, usePagination } from 'hooks';
import { queryTypes as qt, useQueryStates } from 'next-usequerystate';
import { useRouter } from 'next/router';
import { DashboardDatesTable } from './DashboardDatesTable';
import { handleCompleteTask } from './utils';

const dateRangeToLabel = {
  [DashboardDateRange.ALL]: 'All',
  [DashboardDateRange.TODAY]: 'Today',
  [DashboardDateRange.NEXT_7_DAYS]: 'Next 7 Days',
  [DashboardDateRange.THIS_MONTH]: 'This Month',
  [DashboardDateRange.COMPLETED]: 'Completed',
};

const dateTypeButtons = Object.entries(DATE_TYPE_LABELS).map(
  ([value, label]) => ({
    value,
    label,
  }),
);

export default function DashboardDates() {
  const router = useRouter();
  const pagination = usePagination('dueAt', 'asc', 'dates');

  const [qp, setQp] = useQueryStates({
    dateFilter: qt
      .stringEnum(Object.keys(DashboardDateRange))
      .withDefault(DashboardDateRange.NEXT_7_DAYS),
    typeFilter: qt.stringEnum([...DATE_TYPE_VALUES]).withDefault('ALL'),
  });

  const { data, meta, isLoading, error, refresh } = useDashboardDates({
    page: pagination.page,
    pageSize: pagination.pageSize,
    sort: pagination.sortModel.sort as DashboardDatesSortKeys,
    sortDirection: pagination.sortModel.sortDirection,
    dateFilter: qp.dateFilter as DashboardDateRange,
    typeFilter: qp.typeFilter as DateTypeValue,
  });

  const onCompleteTask = async (taskId: string) => {
    await handleCompleteTask(taskId);
    await refresh();
  };

  const handleRowClick = (row: DashboardDatesResponseDto) => {
    router.push(`/clients/${row.client?.id}`);
  };

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        paddingBottom={1}
        alignItems="center"
      >
        <Typography variant="h5">Dates</Typography>
        <Box display="flex" gap={2}>
          <ToggleButtonGroup
            value={qp.dateFilter}
            onChange={(_event, value) =>
              setQp({ dateFilter: value as DashboardDateRange })
            }
            size="small"
            exclusive
          >
            {Object.values(DashboardDateRange).map((type) => (
              <ToggleButton
                key={type}
                value={type}
                selected={qp.dateFilter === type}
              >
                {dateRangeToLabel[type]}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          <ToggleButtonGroup
            value={qp.typeFilter}
            onChange={(_, value) => {
              if (value !== null) {
                setQp({ typeFilter: value });
              }
            }}
            size="small"
            exclusive
          >
            {dateTypeButtons.map(({ value, label }) => (
              <ToggleButton key={value} value={value}>
                {label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>
      </Box>
      <DashboardDatesTable
        {...pagination}
        data={data}
        isLoading={isLoading}
        error={error}
        meta={meta}
        onCompleteTask={onCompleteTask}
        showingCompletedTasks={qp.dateFilter === DashboardDateRange.COMPLETED}
        onRowClick={handleRowClick}
      />
    </div>
  );
}
