import { ExtendedFormDialog } from '@customFormik';
import type { TaskResponseDto } from 'dtos';
import { createTask, updateTask } from 'requests/tasks';
import TripTaskForm from './TripTaskForm';
import formSchema from './schema';

type TripTaskDialogProps = {
  tripId?: string;
  taskId?: string;
  showTripSelector?: boolean;
  open: boolean;
  onSuccess: (task: TaskResponseDto) => void;
  setOpen: (isOpen: boolean) => Promise<void> | void;
  entity?: TaskResponseDto;
  mode: 'add' | 'edit';
};

export default function TripTaskDialog({
  tripId,
  taskId,
  showTripSelector,
  open,
  onSuccess,
  setOpen,
  entity,
  mode,
}: TripTaskDialogProps) {
  const handleSuccess = async (task: TaskResponseDto) => {
    await setOpen(false);
    onSuccess(task);
  };

  return (
    <ExtendedFormDialog
      context={{
        showTripSelector,
      }}
      title={mode === 'add' ? 'New Task' : 'Edit Task'}
      saveButtonText={mode === 'add' ? 'Create Task' : 'Update Task'}
      open={open}
      setOpen={setOpen}
      FormComponent={TripTaskForm}
      onSave={(formValues) =>
        taskId
          ? updateTask({ task: formValues, tripId: tripId as string, taskId })
          : createTask({
              task: formValues,
              tripId: formValues.tripId ?? (tripId as string),
            })
      }
      onSuccess={handleSuccess}
      formSchema={formSchema}
      entity={entity}
    />
  );
}
