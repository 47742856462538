import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import BookingDialog from 'components/bookings/BookingDialog/BookingDialog';
import ClientInvoiceDialog from 'components/clientInvoices/ClientInvoiceDialog';
import {
  type DashboardDueTodayResponseDto,
  DashboardDueTodayType,
} from 'dtos/dashboard.dtos';
import { useDashboardDueToday, usePagination } from 'hooks';
import { queryTypes as qt, useQueryStates } from 'next-usequerystate';
import { useRouter } from 'next/router';
import { enqueueSnackbar as toast } from 'notistack';
import { updateDashboardTask } from 'requests/tasks';
import { DueTodayFilter, type TaskFilterType } from 'services/dashboard/types';
import { DashboardDueTodayTable } from './DashboardDueTodayTable';

const dueTodayFilterToLabel = {
  [DueTodayFilter.ALL]: 'All',
  [DueTodayFilter.TASK]: 'Tasks',
  [DueTodayFilter.PAYMENT]: 'Payments',
  [DueTodayFilter.DATE]: 'Dates',
  [DueTodayFilter.COMPLETED]: 'Completed',
};

const handleCompleteTask = async (taskId: string) => {
  try {
    await updateDashboardTask({ taskId, isCompleted: true });
    toast('Task completed successfully');
  } catch (error) {
    toast(`Error completing task: ${error}`, { variant: 'error' });
  }
};

export default function DashboardOverviewTasks() {
  const router = useRouter();
  const [qp, setQp] = useQueryStates({
    filter: qt
      .stringEnum(Object.keys(DueTodayFilter))
      .withDefault(DueTodayFilter.ALL),
    tripId: qt.string,
    clientInvoiceId: qt.string,
    bookingId: qt.string,
  });

  const pagination = usePagination<DashboardDueTodayResponseDto>(
    'client',
    'asc',
    'dueToday',
    10,
  );
  const { data, meta, isLoading, error, refresh } = useDashboardDueToday({
    page: pagination.page,
    pageSize: pagination.pageSize,
    sortModel: pagination.sortModel,
    filter: qp.filter as DueTodayFilter,
  });

  const onCompleteTask = async (taskId: string) => {
    await handleCompleteTask(taskId);
    await refresh();
  };

  const handleRowClick = (row: DashboardDueTodayResponseDto) => {
    if (row.type === DashboardDueTodayType.BOOKING_PAYMENT) {
      setQp({
        tripId: row.trip?.id,
        bookingId: row.trip?.bookingId,
      });
    } else if (row.type === DashboardDueTodayType.CLIENT_PAYMENT) {
      setQp({
        tripId: row.trip?.id,
        clientInvoiceId: row.id,
      });
    } else if (row.type === DashboardDueTodayType.TASK && row.trip?.id) {
      router.push(`/trips/${row.trip?.id}?tab=tasksList`);
    } else {
      router.push(`/clients/${row.client?.id}`);
    }
  };

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="space-between"
        paddingBottom={1}
        paddingTop={2}
        alignItems="center"
      >
        <Typography variant="h5">Due Today</Typography>
        <ToggleButtonGroup
          value={qp.filter}
          onChange={(_event, value) =>
            setQp({ filter: value as TaskFilterType })
          }
          size="small"
          exclusive
        >
          {Object.values(DueTodayFilter).map((type) => (
            <ToggleButton key={type} value={type} selected={qp.filter === type}>
              {dueTodayFilterToLabel[type]}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Stack>
      <DashboardDueTodayTable
        {...pagination}
        data={data}
        isLoading={isLoading}
        error={error}
        meta={meta}
        onCompleteTask={onCompleteTask}
        onRowClick={handleRowClick}
        showingCompletedTasks={qp.filter === DueTodayFilter.COMPLETED}
      />
      {!!qp.bookingId && !!qp.tripId && (
        <BookingDialog
          tripId={qp.tripId}
          mode="edit"
          bookingId={qp.bookingId ?? undefined}
          open={!!qp.bookingId && !!qp.tripId}
          setOpen={(isOpen) => {
            if (!isOpen) {
              setQp({ bookingId: null, tripId: null });
            }
          }}
          onSuccess={() => {
            setQp({ bookingId: null, tripId: null });
            refresh();
          }}
        />
      )}
      {!!qp.clientInvoiceId && !!qp.tripId && (
        <ClientInvoiceDialog
          tripId={qp.tripId}
          clientInvoiceId={qp.clientInvoiceId ?? undefined}
          mode="edit"
          onSuccess={() => {
            setQp({ clientInvoiceId: null, tripId: null });
            refresh();
          }}
          open={!!qp.clientInvoiceId && !!qp.tripId}
          setOpen={(isOpen) => {
            if (!isOpen) {
              setQp({ clientInvoiceId: null, tripId: null });
            }
          }}
        />
      )}
    </div>
  );
}
