import { useQueryStates } from 'next-usequerystate';
import { parseAsStringEnum } from 'nuqs';
import DashboardTabPanels from './DashboardTabPanels';
import DashboardTabs from './DashboardTabs';
import { DashboardTabs as DashboardTabsEnum } from './types';

export default function Dashboard() {
  const [queryParams, setQueryParams] = useQueryStates({
    tab: parseAsStringEnum<DashboardTabsEnum>(
      Object.values(DashboardTabsEnum),
    ).withDefault(DashboardTabsEnum.OVERVIEW),
  });

  return (
    <>
      <DashboardTabs
        tab={queryParams.tab}
        onTabChange={(tab: DashboardTabsEnum) => {
          setQueryParams({ tab });
        }}
      />

      <DashboardTabPanels tab={queryParams.tab} />
    </>
  );
}
