import type {
  DashboardTripsPaginatedResponseDto,
  DashboardTripsResponseDto,
  DashboardTripsSortKey,
} from 'dtos/dashboard.dtos';
import useSWR from 'swr';
import type { PaginatedData } from 'types/paginatedData';
import { fetcher } from 'utils/client/swr/jsonFetcher';

type UseDashboardTripsProps = {
  status?: string;
  sort: DashboardTripsSortKey;
  sortDirection: 'asc' | 'desc';
  page: number;
  pageSize: number;
};

export default function useDashboardTrips({
  status,
  sort,
  sortDirection,
  page,
  pageSize,
}: UseDashboardTripsProps) {
  const params = new URLSearchParams({
    sort: sort as string,
    sortDirection: sortDirection as string,
    page: page.toString(),
    pageSize: pageSize.toString(),
    ...(status ? { status } : {}),
  });

  const { data, isLoading, error, mutate } = useSWR<
    PaginatedData<DashboardTripsResponseDto>,
    Error
  >(`/dashboard/trips?${params}`, fetcher);

  const { data: trips = [], meta } = data || {};

  return {
    data: trips,
    meta,
    isLoading,
    error,
    refresh: mutate,
  };
}
