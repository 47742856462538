import type {
  StatusResponseDto,
  TaskCreateRequestDto,
  TaskResponseDto,
  TaskUpdateRequestDto,
} from 'dtos';
import ky from 'utils/client/ky';

export const createTask = async ({
  task,
  tripId,
}: {
  task: TaskCreateRequestDto;
  tripId: string;
}): Promise<TaskResponseDto> => {
  const endpoint = `/api/trips/${tripId}/tasks`;

  return ky
    .post(endpoint, {
      json: task,
    })
    .json<TaskResponseDto>();
};

export const updateTask = async ({
  task,
  tripId,
  taskId,
}: {
  task: TaskUpdateRequestDto;
  tripId: string;
  taskId: string;
}): Promise<TaskResponseDto> => {
  const endpoint = `/api/trips/${tripId}/tasks/${taskId}`;

  return ky
    .put(endpoint, {
      json: task,
    })
    .json<TaskResponseDto>();
};

export const deleteTask = async ({
  taskId,
  tripId,
}: {
  taskId: string;
  tripId: string;
}): Promise<TaskResponseDto> => {
  const endpoint = `/api/trips/${tripId}/tasks/${taskId}`;

  return ky.delete(endpoint).json<TaskResponseDto>();
};

export const applyTasksTemplate = async ({
  tripId,
  tasksTemplateIds,
  assignedAgencyUserIds,
}: {
  tripId: string;
  tasksTemplateIds: string[];
  assignedAgencyUserIds: string[];
}) => {
  const endpoint = `/api/trips/${tripId}/tasks-templates`;

  return ky
    .post(endpoint, {
      json: { tasksTemplateIds, assignedAgencyUserIds },
    })
    .json<StatusResponseDto>();
};

export const updateDashboardTask = async ({
  taskId,
  isCompleted,
}: {
  taskId: string;
  isCompleted: boolean;
}) => {
  const endpoint = `/api/dashboard/tasks/${taskId}`;

  return ky
    .put(endpoint, {
      json: { isCompleted },
    })
    .json<StatusResponseDto>();
};
