import { Stack } from '@mui/material';
import DashboardOverviewTasks from './DashboardOverviewTasks';
import DashboardOverviewTrips from './DashboardOverviewTrips';
import DashboardQuickAddButtons from './DashboardQuickAddButtons';

export default function DashboardOverview() {
  return (
    <Stack>
      <DashboardQuickAddButtons />
      <DashboardOverviewTasks />
      <DashboardOverviewTrips />
    </Stack>
  );
}
