export enum TripTab {
  bookings = 'bookings',
  clientInvoices = 'clientInvoices',
  invoices = 'invoices',
  virtualCard = 'virtualCard',
  supplierInvoices = 'supplierInvoices',
  travelers = 'travelers',
  notes = 'notes',
  summary = 'summary',
  wallet = 'wallet',
  tasksList = 'tasksList',
  attachments = 'attachments',
  emails = 'emails',
}
