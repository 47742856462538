import {
  AdvisorMultiselect,
  DatePicker,
  Select,
  TextField,
} from '@customFormik';
import { Stack } from '@mui/material';
import TimePicker from 'components/common/forms/formik/TimePicker';
import TripSelector from 'components/common/forms/formik/TripSelector';
import {
  TaskTriggerTiming,
  TaskTriggerTimingQualifier,
  TaskTriggerType,
} from 'dtos/workflowTemplates.types';
import { useFormikContext } from 'formik';
import {
  FieldNames,
  type SchemaContext,
  type TripTaskFormValues,
} from './schema';

const getTriggerDisplayName = (trigger: TaskTriggerType): string => {
  switch (trigger) {
    case TaskTriggerType.NONE:
      return 'None';
    case TaskTriggerType.TRIP_START:
      return 'Trip Start';
    case TaskTriggerType.TRIP_END:
      return 'Trip End';
    case TaskTriggerType.SPECIFIC_DATE:
      return 'Specific Date';
  }
};

const getTriggerTimingDisplayName = (timing: TaskTriggerTiming): string => {
  switch (timing) {
    case TaskTriggerTiming.BEFORE_AFTER:
      return 'Before/After';
    case TaskTriggerTiming.DAY_OF:
      return 'Day Of';
  }
};

const getTimingQualifierDisplayName = (
  qualifier: TaskTriggerTimingQualifier,
): string => {
  switch (qualifier) {
    case TaskTriggerTimingQualifier.BEFORE:
      return 'Before';
    case TaskTriggerTimingQualifier.AFTER:
      return 'After';
  }
};

type TripTaskFormProps = {
  context: SchemaContext;
};

const TripTaskForm = ({ context: { showTripSelector } }: TripTaskFormProps) => {
  const { values, setFieldValue, errors } =
    useFormikContext<TripTaskFormValues>();
  const showTriggerFields = values.trigger !== TaskTriggerType.NONE;
  const showQualifierAndDuration =
    showTriggerFields &&
    values.triggerTiming === TaskTriggerTiming.BEFORE_AFTER;
  const showDateTimePickers = values.trigger === TaskTriggerType.SPECIFIC_DATE;

  return (
    <Stack spacing={2}>
      {showTripSelector && (
        <TripSelector name={FieldNames.TRIP} label="Trip" required />
      )}

      <TextField
        name={FieldNames.TITLE}
        label="Title"
        required
        fullWidth
        helperText="A short, descriptive title"
        autoFocus
      />

      <TextField name={FieldNames.DESCRIPTION} label="Description" fullWidth />

      <AdvisorMultiselect
        name={FieldNames.ASSIGNEES}
        label="Assignees"
        required
        disableWhenEmpty
        onChange={(selectedAssignees) =>
          setFieldValue(
            FieldNames.ASSIGNEES,
            selectedAssignees?.map((assignee) => ({
              id: assignee.agencyUserId,
              name: assignee.name,
            })) ?? [],
          )
        }
      />

      {process.env.NEXT_PUBLIC_WORKFLOW_TEMPLATES_ENABLED === '1' ? (
        <>
          <Select
            name={FieldNames.TRIGGER}
            label="Trigger"
            required
            options={Object.values(TaskTriggerType).map((trigger) => ({
              value: trigger,
              label: getTriggerDisplayName(trigger),
            }))}
          />

          {showTriggerFields && (
            <>
              {showDateTimePickers ? (
                <Stack direction="row" spacing={2}>
                  <DatePicker name={FieldNames.DUE_AT} label="Date" required />
                  <TimePicker
                    name={FieldNames.DUE_AT}
                    referenceDate={values[FieldNames.DUE_AT]}
                    label="Time"
                    required
                  />
                </Stack>
              ) : (
                <>
                  <Select
                    name={FieldNames.TRIGGER_TIMING}
                    label="Timing"
                    required
                    options={Object.values(TaskTriggerTiming).map((timing) => ({
                      value: timing,
                      label: getTriggerTimingDisplayName(timing),
                    }))}
                  />
                  {showQualifierAndDuration && (
                    <Stack direction="row" spacing={2}>
                      <Select
                        name={FieldNames.TRIGGER_TIMING_QUALIFIER}
                        label="Qualifier"
                        required
                        options={Object.values(TaskTriggerTimingQualifier).map(
                          (qualifier) => ({
                            value: qualifier,
                            label: getTimingQualifierDisplayName(qualifier),
                          }),
                        )}
                      />
                      <TextField
                        name={FieldNames.TRIGGER_TIMING_DURATION}
                        label="Duration"
                        type="number"
                        required
                        InputProps={{
                          endAdornment: 'days',
                        }}
                      />
                    </Stack>
                  )}
                </>
              )}
            </>
          )}
        </>
      ) : (
        <DatePicker name={FieldNames.DUE_AT} label="Date" fullWidth required />
      )}
    </Stack>
  );
};

export default TripTaskForm;
