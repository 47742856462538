import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import type { DashboardTasksResponseDto } from 'dtos/dashboard.dtos';
import { useDashboardTasks, usePagination } from 'hooks';
import { queryTypes as qt, useQueryStates } from 'next-usequerystate';
import { useRouter } from 'next/router';
import {
  TaskBasis,
  TaskFilter,
  type TaskFilterType,
} from 'services/dashboard/types';
import { DashboardTasksTable } from './DashboardTasksTable';
import { handleCompleteTask } from './utils';

const taskFilterToLabel = {
  [TaskFilter.ALL]: 'All',
  [TaskFilter.TODAY]: 'Due Today',
  [TaskFilter.NEXT_7_DAYS]: 'Next 7 Days',
  [TaskFilter.OVERDUE]: 'Overdue',
  [TaskFilter.UPCOMING]: 'Upcoming',
  [TaskFilter.COMPLETED]: 'Completed',
  [TaskFilter.TBD]: 'TBD',
};

export default function DashboardTasks() {
  const router = useRouter();
  const [qp, setQp] = useQueryStates({
    filter: qt
      .stringEnum(Object.keys(TaskFilter))
      .withDefault(TaskFilter.NEXT_7_DAYS),
  });

  const pagination = usePagination<DashboardTasksResponseDto>(
    'dueAt',
    'asc',
    'tasks',
  );
  const { data, meta, isLoading, error, refresh } = useDashboardTasks({
    page: pagination.page,
    pageSize: pagination.pageSize,
    sortModel: pagination.sortModel,
    basis: TaskBasis.TRIP,
    filter: qp.filter as TaskFilterType,
  });

  const onCompleteTask = async (taskId: string) => {
    await handleCompleteTask(taskId);
    await refresh();
  };

  const handleRowClick = (row: DashboardTasksResponseDto) => {
    if (row.trip?.id) {
      router.push(`/trips/${row.trip.id}?tab=tasksList`);
    } else {
      router.push(`/clients/${row.client?.id}`);
    }
  };

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        paddingBottom={1}
        alignItems="center"
      >
        <Typography variant="h5">Tasks</Typography>
        <ToggleButtonGroup
          value={qp.filter}
          onChange={(_event, value) =>
            setQp({ filter: value as TaskFilterType })
          }
          size="small"
          exclusive
        >
          {Object.keys(taskFilterToLabel).map((type) => (
            <ToggleButton key={type} value={type} selected={qp.filter === type}>
              {taskFilterToLabel[type as keyof typeof taskFilterToLabel]}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>
      <DashboardTasksTable
        {...pagination}
        data={data}
        isLoading={isLoading}
        error={error}
        meta={meta}
        onCompleteTask={onCompleteTask}
        showingCompletedTasks={qp.filter === TaskFilter.COMPLETED}
        onRowClick={handleRowClick}
      />
    </div>
  );
}
