import { Check } from '@mui/icons-material';
import { IconButton, Stack, Tooltip } from '@mui/material';
import UserWithInitialsChip from 'components/common/UserWithInitialsChip';
import Grid, { type GridProps } from 'components/common/grids/Grid';
import type { DashboardDatesResponseDto } from 'dtos/dashboard.dtos';
import type { TypedGridColDef } from 'types/typedGridColDef';
import { niceShortDate } from 'utils/client/formatting';
import { UserWithInitials } from './utils';

const getColumns = ({
  onCompleteTask,
  showingCompletedTasks,
}: {
  onCompleteTask: (taskId: string) => void;
  showingCompletedTasks: boolean;
}): TypedGridColDef<DashboardDatesResponseDto>[] => [
  {
    field: 'id',
    headerName: 'ID',
    hide: true,
  },
  {
    field: 'subject',
    flex: 1,
    headerName: 'Subject',
    renderCell: ({ row, value }) => (
      <Stack direction="row" gap={1} alignItems="center">
        <IconButton
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            onCompleteTask(row.id);
          }}
          disabled={showingCompletedTasks}
        >
          <Check
            color={showingCompletedTasks ? 'success' : 'action'}
            sx={{ width: 20, height: 20 }}
          />
        </IconButton>
        {value}
      </Stack>
    ),
  },
  {
    field: 'dueAt',
    headerName: 'Date',
    flex: 1,
    renderCell: ({ value }) => niceShortDate(value),
  },
  {
    field: 'client',
    headerName: 'Client',
    flex: 1,
    renderCell: ({ value }) =>
      value ? (
        <UserWithInitialsChip
          userName={value.name}
          avatarColor={value.color}
          size="medium"
          redirectUrl={`/clients/${value.id}`}
        />
      ) : null,
  },
  {
    field: 'groups',
    headerName: 'Group',
    flex: 1,
    renderCell: ({ value }) => {
      if (!value) return '';
      if (!Array.isArray(value)) {
        console.warn('groups is not an array:', value);
        return '';
      }
      if (value.length === 0) return '';

      if (value.length === 1) {
        return value[0].name;
      }

      return (
        <Tooltip
          title={value
            .slice(1)
            .map((g: { name: string }) => g.name)
            .join(', ')}
        >
          <span>{`${value[0].name}...`}</span>
        </Tooltip>
      );
    },
  },
  {
    field: 'advisor',
    headerName: 'Advisor',
    flex: 1,
    renderCell: ({ value }) =>
      value ? (
        <UserWithInitials
          userName={value.name}
          avatarColor={value.color}
          profileImageUrl={value.profileImageUrl}
        />
      ) : null,
  },
];

export const DashboardDatesTable = (
  props: Omit<
    GridProps<DashboardDatesResponseDto>,
    'columns' | 'initialState'
  > & {
    showingCompletedTasks: boolean;
    onCompleteTask: (taskId: string) => void;
  },
) => {
  return (
    <Grid
      {...props}
      columns={getColumns({
        onCompleteTask: props.onCompleteTask,
        showingCompletedTasks: props.showingCompletedTasks,
      })}
      getRowId={(row) => `${row.id}_${row.type}_${row.dueAt}`}
      sx={{ height: 'calc(100vh - 200px)' }}
    />
  );
};
