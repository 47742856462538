import { ArrowForward, StoreOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import UserWithInitialsChip from 'components/common/UserWithInitialsChip';
import Grid, { type GridProps } from 'components/common/grids/Grid';
import type { CurrencyDto } from 'dtos';
import {
  DashboardParticipantType,
  DashboardPaymentType,
} from 'dtos/dashboard.dtos';
import type { DashboardPaymentsResponseDto } from 'dtos/dashboard.dtos';
import type { TypedGridColDef } from 'types/typedGridColDef';
import { formatMoneyStr, niceShortDate } from 'utils/client/formatting';
import { UserWithInitials } from './utils';

type GetColumnsOptions = {
  homeCurrency?: CurrencyDto;
};

const getColumns = ({
  homeCurrency,
}: GetColumnsOptions): TypedGridColDef<DashboardPaymentsResponseDto>[] => [
  {
    field: 'type',
    headerName: '',
    renderCell: ({ value }) => (
      <Stack direction="row" gap={1}>
        {value === DashboardPaymentType.BOOKING ? (
          <ArrowForward color="action" fontSize="small" />
        ) : null}
        <StoreOutlined color="action" fontSize="small" />
        {value === DashboardPaymentType.CLIENT ? (
          <ArrowForward color="action" fontSize="small" />
        ) : null}
      </Stack>
    ),
    sortable: false,
    maxWidth: 80,
  },
  {
    field: 'subject',
    flex: 1,
    headerName: 'Subject',
  },
  {
    field: 'from',
    flex: 1,
    headerName: 'From',
    renderCell: ({ value }) => {
      if (value.type === DashboardParticipantType.CLIENT) {
        return (
          <UserWithInitialsChip
            userName={value.name}
            avatarColor={value.color}
            size="medium"
            redirectUrl={`/clients/${value.id}`}
          />
        );
      }
      if (value.type === DashboardParticipantType.GROUP) {
        return (
          <UserWithInitialsChip
            userName={value.name}
            avatarColor={value.color}
            size="medium"
            redirectUrl={`/clients/corporate/${value.id}`}
          />
        );
      }
      return value.name;
    },
  },
  {
    field: 'to',
    flex: 1,
    headerName: 'To',
  },
  {
    field: 'amount',
    headerName: 'Amount',
    flex: 1,
    valueGetter: ({ value }) =>
      formatMoneyStr(value, `${homeCurrency?.symbol}0`, homeCurrency?.symbol),
  },
  {
    field: 'dueDate',
    headerName: 'Due',
    flex: 1,
    valueGetter: ({ value }) => niceShortDate(value),
  },
  {
    field: 'client',
    headerName: 'Client',
    flex: 1,
    renderCell: ({ value }) =>
      value.type === DashboardParticipantType.CLIENT ? (
        <UserWithInitialsChip
          userName={value.name}
          avatarColor={value.color}
          size="medium"
          redirectUrl={`/clients/${value.id}`}
        />
      ) : (
        value.name
      ),
  },
  {
    field: 'advisor',
    headerName: 'Advisor',
    flex: 1,
    renderCell: ({ value }) => (
      <UserWithInitials
        userName={value.name}
        avatarColor={value.color}
        profileImageUrl={value.profileImageUrl}
      />
    ),
  },
];

export const DashboardPaymentsTable = (
  props: Omit<
    GridProps<DashboardPaymentsResponseDto>,
    'columns' | 'initialState'
  > & {
    homeCurrency?: CurrencyDto;
  },
) => {
  return (
    <Grid
      {...props}
      columns={getColumns({ homeCurrency: props.homeCurrency })}
      sx={{ height: 'calc(100vh - 200px)' }}
    />
  );
};
