import T from 'components/common/T';
import type React from 'react';

import ClientOnlyPortal from './ClientOnlyPortal';

type PageTitle = {
  title: string;
};

const PageTitle: React.FC<PageTitle> = ({ title }) => {
  return (
    <ClientOnlyPortal selector="#page-title">
      <T fontSize={20} fontWeight={500}>
        {title}
      </T>
    </ClientOnlyPortal>
  );
};

export default PageTitle;
