import { CancelRounded } from '@mui/icons-material';
import { Avatar, Chip, alpha } from '@mui/material';
import T from './T';

type UserWithInitialsChipProps = {
  userName: string;
  avatarColor?: string;
  onDelete?: () => void;
  size?: 'small' | 'medium';
  profileImageUrl?: string;
  redirectUrl?: string;
};

const UserWithInitialsChip = ({
  userName,
  avatarColor = '#fff',
  size = 'small',
  onDelete,
  profileImageUrl,
  redirectUrl,
}: UserWithInitialsChipProps) => {
  const StyledAvatar = () => (
    <Avatar
      variant="circular"
      sx={{
        width: size === 'small' ? 16 : 24,
        height: size === 'small' ? 16 : 24,
        bgcolor: alpha(avatarColor, 0.2),
        ...(!profileImageUrl && {
          paddingTop: 0.3,
        }),
      }}
      src={profileImageUrl}
    >
      <T variant="caption" sx={{ color: avatarColor }}>
        {userName[0]?.toUpperCase() ?? '--'}
      </T>
    </Avatar>
  );

  return (
    <Chip
      variant="outlined"
      label={userName}
      avatar={<StyledAvatar />}
      sx={{
        pl: 0.5,
        '& .MuiChip-label': { pl: 0.75, pr: '11px' },
      }}
      size={size}
      {...(onDelete ? { onDelete, deleteIcon: <CancelRounded /> } : {})}
      {...(redirectUrl && {
        clickable: true,
        component: 'a',
        href: redirectUrl,
      })}
    />
  );
};

export default UserWithInitialsChip;
