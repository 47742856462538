import { Button, Card, Stack } from '@mui/material';
import T from 'components/common/T';
import Grid from 'components/common/grids/Grid';
import LeadStageSelectorChip from 'components/leads/LeadStageSelectorChip';
import type {
  DashboardTripsResponseDto,
  DashboardTripsSortKey,
} from 'dtos/dashboard.dtos';
import { usePagination } from 'hooks';
import useDashboardTripStatuses from 'hooks/useDashboardTripStatuses';
import useDashboardTrips from 'hooks/useDashboardTrips';
import { queryTypes, useQueryStates } from 'next-usequerystate';
import { useRouter } from 'next/router';
import { useLayoutEffect, useRef, useState } from 'react';
import { updateTripStage } from 'requests/trips';
import type { TypedGridColDef } from 'types/typedGridColDef';
import { niceShortDate } from 'utils/client/formatting';

const HEADER_HEIGHT = 112;
const TABS_HEIGHT = 50;

const getColumns = (
  refresh: () => Promise<void>,
): TypedGridColDef<DashboardTripsResponseDto>[] => [
  {
    field: 'travelerName',
    headerName: 'Traveler Name',
    flex: 1,
  },
  {
    field: 'name',
    headerName: 'Trip Name',
    flex: 1,
  },
  {
    field: 'destinations',
    headerName: 'Destination(s)',
    flex: 1,
  },
  {
    field: 'advisorName',
    headerName: 'Advisor',
    flex: 1,
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    flex: 1,
    valueFormatter: ({ value }) => niceShortDate(value),
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    flex: 1,
    valueFormatter: ({ value }) => niceShortDate(value),
  },
  {
    field: 'stage',
    headerName: 'Status',
    flex: 1,
    renderCell: ({ row, value }) => (
      <LeadStageSelectorChip
        selectedStage={value}
        useTripVerbiage
        size="small"
        onSelect={async (stage) => {
          await updateTripStage({
            tripId: row.id,
            stageId: stage.id,
          });
          await refresh();
        }}
      />
    ),
  },
];

type DashboardTripsTableProps = {
  fixedTableHeight?: boolean;
};

export default function DashboardTripsTable({
  fixedTableHeight = false,
}: DashboardTripsTableProps) {
  const [tableHeight, setTableHeight] = useState<string | null>(
    fixedTableHeight ? '750px' : null,
  );
  const statusesRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const [queryParams, setQueryParams] = useQueryStates({
    status: queryTypes.string,
  });
  const pagination = usePagination('startDate', 'asc', 'trips', 20);

  const {
    data: statuses,
    isLoading: areStatusesLoading,
    refresh: refreshStatuses,
  } = useDashboardTripStatuses();
  const {
    data: trips,
    meta,
    isLoading: areTripsLoading,
    refresh: refreshTrips,
  } = useDashboardTrips({
    status: queryParams.status ?? undefined,
    sort: pagination.sortModel.sort as DashboardTripsSortKey,
    sortDirection: pagination.sortModel.sortDirection,
    page: pagination.page,
    pageSize: pagination.pageSize,
  });

  useLayoutEffect(() => {
    if (fixedTableHeight) {
      return;
    }

    const updateTableHeight = () => {
      if (statusesRef.current) {
        const totalOffset = HEADER_HEIGHT + TABS_HEIGHT;
        setTableHeight(
          `calc(100vh - ${statusesRef.current.clientHeight + totalOffset}px)`,
        );
      }
    };

    updateTableHeight();

    const resizeObserver = new ResizeObserver(updateTableHeight);
    if (statusesRef.current) {
      resizeObserver.observe(statusesRef.current);
    }

    window.addEventListener('resize', updateTableHeight);

    return () => {
      if (statusesRef.current) {
        resizeObserver.unobserve(statusesRef.current);
      }
      window.removeEventListener('resize', updateTableHeight);
      resizeObserver.disconnect();
    };
  }, [statusesRef.current?.clientHeight, statuses]);

  const columns = getColumns(async () => {
    await refreshStatuses();
    await refreshTrips();
  });

  if (!statuses) {
    return null;
  }

  return (
    <Stack>
      <Stack
        direction="row"
        alignItems="center"
        flexWrap="wrap"
        gap={1}
        mb={1}
        ref={statusesRef}
      >
        {statuses.map((status, index) => {
          const isSelected = status.name.toLowerCase() === queryParams.status;
          return (
            <Button
              component={Card}
              key={`${status.name}-${index}`}
              sx={{
                py: 1.5,
                px: 2,
                textTransform: 'none',
                color: 'inherit',
                ...(isSelected
                  ? {
                      backgroundColor: 'primary.main',
                      color: 'primary.contrastText',
                      '&:hover': {
                        backgroundColor: 'primary.dark',
                      },
                    }
                  : {}),
              }}
              onClick={() =>
                setQueryParams({
                  status: isSelected ? null : status.name.toLowerCase(),
                })
              }
            >
              <Stack>
                <T variant="h5">{status.count}</T>
                <T
                  variant="body2"
                  color={isSelected ? 'primary.contrastText' : 'text.secondary'}
                >
                  {status.name}
                </T>
              </Stack>
            </Button>
          );
        })}
      </Stack>

      <Grid
        {...pagination}
        columns={columns}
        data={trips}
        meta={meta}
        isLoading={areTripsLoading || areStatusesLoading}
        sx={{ height: tableHeight }}
        onRowClick={(row) => router.push(`/trips/${row.id}`)}
      />
    </Stack>
  );
}
