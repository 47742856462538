import { Stack } from '@mui/material';
import TabPanel from 'components/common/TabPanel';
import DashboardDates from './DashboardDates';
import DashboardOverview from './DashboardOverview';
import DashboardPayments from './DashboardPayments';
import DashboardTasks from './DashboardTasks';
import DashboardTripsTable from './DashboardTripsTable';
import { DashboardTabs } from './types';

type DashboardTabPanelsProps = {
  tab: DashboardTabs;
};

export default function DashboardTabPanels({ tab }: DashboardTabPanelsProps) {
  return (
    <Stack>
      <TabPanel value={tab} index={DashboardTabs.OVERVIEW}>
        <DashboardOverview />
      </TabPanel>
      <TabPanel value={tab} index={DashboardTabs.TASKS} sx={{ pt: 2 }}>
        <DashboardTasks />
      </TabPanel>
      <TabPanel value={tab} index={DashboardTabs.PAYMENTS} sx={{ pt: 2 }}>
        <DashboardPayments />
      </TabPanel>
      <TabPanel value={tab} index={DashboardTabs.DATES} sx={{ pt: 2 }}>
        <DashboardDates />
      </TabPanel>
      <TabPanel value={tab} index={DashboardTabs.TRIPS} sx={{ pt: 2 }}>
        <DashboardTripsTable />
      </TabPanel>
    </Stack>
  );
}
