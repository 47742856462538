import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Box,
  Chip,
  type ChipProps,
  List,
  ListItem,
  ListItemButton,
  Popover,
  Stack,
  TextField,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';

export interface ChipDropdownSelectorProps<T> {
  selected?: T;
  valueSelector: (listItem: T) => string;
  idSelector?: (listItem: T) => string;
  listItems: T[];
  onSelect: (listItem: T) => Promise<void>;
  chipProps?: ChipProps;
  showDropdownIcon?: boolean;
  searchLabel?: string;
  listItemRenderer?: (
    listItem: T,
    index: number,
    elements: T[],
  ) => React.ReactElement;
}
export default function ChipDropdownSelector<T extends { id: string }>({
  selected,
  listItems,
  valueSelector,
  idSelector = (listItem) => listItem.id,
  onSelect,
  chipProps,
  showDropdownIcon = true,
  searchLabel,
  listItemRenderer,
}: ChipDropdownSelectorProps<T>) {
  const [open, setOpen] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [searchInput, setSearchInput] = useState('');
  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(
    null,
  );

  useEffect(() => {
    if (open && searchInputRef.current) searchInputRef?.current.focus();
  }, [searchInputRef, open]);

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget as EventTarget & Element);
    setOpen(true);
  };

  const handleClose = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setAnchorEl(null);
    setOpen(false);
  };

  function searchFilter(listItem: T) {
    if (!searchInput) return true;
    return valueSelector(listItem)
      .toLowerCase()
      .includes(searchInput.toLowerCase());
  }

  function renderListItem(listItem: T): React.ReactElement {
    return (
      <ListItemButton
        key={idSelector(listItem)}
        disabled={selected && idSelector(listItem) === idSelector(selected)}
      >
        {valueSelector(listItem)}
      </ListItemButton>
    );
  }

  return (
    <>
      <Chip
        {...(chipProps || {})}
        clickable
        size="small"
        sx={{
          ...chipProps?.sx,
        }}
        deleteIcon={(showDropdownIcon && <ArrowDropDownIcon />) || undefined}
        {...((showDropdownIcon && { onDelete: (e) => handleClick(e) }) || {})}
        onClick={handleClick}
      />
      <Popover
        keepMounted
        id="chip-dropdown-selector-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {searchLabel && (
            <Stack
              sx={{
                p: 1,
              }}
            >
              <TextField
                fullWidth
                inputRef={searchInputRef}
                variant="filled"
                label={searchLabel}
                value={searchInput}
                onChange={({ target: { value } }) => {
                  setSearchInput(value);
                }}
              />
            </Stack>
          )}
          <Stack
            sx={{
              flex: 1,
              overflow: 'auto',
              maxHeight: 480,
              minWidth: 300,
            }}
          >
            <List>
              {listItems.filter(searchFilter).map((item, ...metaData) => (
                <ListItem
                  disablePadding
                  key={idSelector(item)}
                  onClick={(e) => {
                    handleClose(e);
                    void onSelect(item);
                  }}
                >
                  {(listItemRenderer || renderListItem)(item, ...metaData)}
                </ListItem>
              ))}
            </List>
          </Stack>
        </Box>
      </Popover>
    </>
  );
}
