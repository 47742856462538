import type { DashboardTripStatusesResponseDto } from 'dtos/dashboard.dtos';
import useSWR from 'swr';
import { fetcher } from 'utils/client/swr/jsonFetcher';

export default function useDashboardTripStatuses() {
  const { data, isLoading, error, mutate } = useSWR<
    DashboardTripStatusesResponseDto,
    Error
  >('/dashboard/trips/statuses', fetcher);

  return { data, isLoading, error, refresh: mutate };
}
