import { Button, Stack } from '@mui/material';
import T from 'components/common/T';
import { useQueryStates } from 'next-usequerystate';
import { parseAsStringEnum } from 'nuqs';
import DashboardTripsTable from './DashboardTripsTable';
import { DashboardTabs as DashboardTabsEnum } from './types';
import { DashboardOverviewTripsTooltip } from './utils';

export default function DashboardOverviewTrips() {
  const [_, setQueryParams] = useQueryStates({
    tab: parseAsStringEnum<DashboardTabsEnum>(Object.values(DashboardTabsEnum)),
  });

  return (
    <Stack mt={2} borderTop="1px solid" borderColor="divider">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        my={2}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <T variant="h5">Trips</T>
          <DashboardOverviewTripsTooltip color="action" />
        </Stack>
        <Button
          variant="text"
          size="small"
          onClick={() => setQueryParams({ tab: DashboardTabsEnum.TRIPS })}
        >
          View All Trips
        </Button>
      </Stack>
      <DashboardTripsTable fixedTableHeight />
    </Stack>
  );
}
